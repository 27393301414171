import React, { useState } from "react";
import Facebook from "./images/follow/Facebook.svg";
import Instagram from "./images/follow/Instagram.svg";
import LinkedIn from "./images/follow/LinkedIn.svg";
import Youtube from "./images/follow/Youtube.svg";
import * as s from "../index.module.scss";

const FollowUs = () => {
  return (
    <div>
      <h5 className="bold mb-3 pb-1">Follow us on</h5>
      <ul className={s.list_view_follow}>
        <li>
          <a href="https://www.linkedin.com/company/clectiq/">
            <img src={LinkedIn} alt="LinkedIn" loading="lazy" />
          </a>
        </li>
        <li>
          <a href="https://web.facebook.com/ClectiqOfficial">
            <img src={Facebook} alt="Facebook" loading="lazy" />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UC1HnB14Pbn_P_upTvE0n6Gg">
            <img src={Youtube} alt="Youtub" loading="lazy" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/clectiq/">
            <img src={Instagram} alt="Instagram" loading="lazy" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FollowUs;
