import React, { useState } from "react";
import FooterLogo from "./images/FooterLogo.svg";
import { Link } from "gatsby";
import { isMobile } from "../../../../../utils";
import * as s from "../index.module.scss";
import Home from "./images/icon/home.svg";
import Email from "./images/icon/mail.svg";
import Call from "./images/icon/call.svg";
import { CallOutline } from "react-ionicons";
import { useStaticQuery, graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

const Intro = () => {
  const [width] = useState(isMobile ? 295 : 380);

  
  const data = useStaticQuery(graphql`
    query {
      prismicFooter(dataRaw: {}) {
        dataRaw
      }
    }
  `)
  const pageDataFooter = data.prismicFooter.dataRaw;

  return (
    <div>
      <Link to={"/"}>
        <img src={FooterLogo} alt="FooterLogo" width={width} className={s.footer_logo} loading="lazy" />

      </Link>
      <div className="paragraph medium mb-0">
        <PrismicRichText field={pageDataFooter.description} />
      </div>
      <p className={`paragraph medium mb-0 d-flex ${s.home_icon_box}`}>

        <img className={`${s.icon}`} src={Home} alt="Home" loading="lazy"/>
        <label>{pageDataFooter.address}</label>

      </p>
      <a
        href={`mailto: ${pageDataFooter.email}`}
        className={`paragraph medium mb-0 d-flex ${s.email_icon_box}`}
      >
        <img className={s.icon} src={Email}  alt="Email" loading="lazy"/>
        {pageDataFooter.email}
      </a>
      <a
        href={`tel: ${pageDataFooter.contact_number}`}
        className={`paragraph medium mb-0 d-flex ${s.email_icon_box}`}
      >
        <img className={s.icon} src={Call} alt="Call" loading="lazy"/>
        {pageDataFooter.contact_number}
      </a>
    </div>
  );
};

export default Intro;
