import React, {useState , useEffect} from "react";
import { Link } from "gatsby";
import * as s from "./index.module.scss";

const ServicesLinks = () => {
  
  const [expanded, setExpanded] = useState(false);

  const scroll = (e)=>{
    e.preventDefault();
    const id = e.currentTarget.getAttribute('href').slice(2);
    const url = window.location.href;
    const url_path = window.location.origin;
    const url_name = window.location.pathname;
    
    if(url_name!=='/'){
      window.location.href =`${url_path}/services/design-and-development/#people_love`;
    }else{
      const element = document.getElementById(id);
      const navHeight = document.querySelector('.my_Nav').getBoundingClientRect().height;
      const linksHeight = document.querySelector('.links-nav').getBoundingClientRect().height;
      let position = element.offsetTop - navHeight;
      if(navHeight > 100){
          position += linksHeight;
      }
      window.scrollTo({
          top: position,
          left:0,
      });
      setExpanded(false)
    }
  }

  return <div>
    <h5 className="bold">Services</h5>
    <ul className={s.list_view_box}>
      <li className="mb-2">
        <Link className="paragraph medium" 
        to="/services/mobile-app-development" 
        onClick={(e)=>scroll("/services/mobile-app-development")}>
          Mobile App Development
        </Link>
      </li>
      <li className="mb-2">
        <Link className="paragraph medium" 
        to="/services/design-and-development/#people_love" 
        onClick={(e)=>scroll("/services/design-and-development/#people_love")}>
          Web App Development
        </Link>
      </li>
      <li className="mb-2">
        <Link className="paragraph medium" 
        to="/services/design-and-development/#people_love" 
        onClick={(e)=>scroll("/services/design-and-development/#people_love")}>
          Website Development
        </Link>
      </li>
      <li className="mb-2">
        <Link className="paragraph medium" 
        to="/services/performance-marketing/" 
        onClick={(e)=>scroll("services/performance-marketing/")}>
          Paid Marketing
        </Link>
      </li>
      <li className="mb-2">
        <Link className="paragraph medium" 
        to="/services/performance-marketing/" 
        onClick={(e)=>scroll("services/performance-marketing/")}>
          SEO
        </Link>
      </li>
      <li className="mb-0">
        <Link className="paragraph medium" 
        to="/services/performance-marketing/" 
        onClick={(e)=>scroll("services/performance-marketing/")}>
          Conversion Optimization
        </Link>
      </li>
    </ul>
  </div>;
};

export default ServicesLinks;
