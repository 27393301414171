import React from "react";
// import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
// import componentResolver from "./config/prismic/componentResolver";

import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "./src/styles/bootstrap/index.scss";
import "./src/styles/global.scss";
import "./src/styles/typography.scss";

const WrapWithProviders = ({ children }) => (
  <>
    {/*<PrismicPreviewProvider*/}
    {/*  repositoryConfigs={[*/}
    {/*    {*/}
    {/*      repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,*/}
    {/*      linkResolver: require("./config/prismic/linkResolver.js")*/}
    {/*        .linkResolver,*/}
    {/*      componentResolver,*/}
    {/*    },*/}
    {/*  ]}*/}
    {/*>*/}
    {children}
    {/*</PrismicPreviewProvider>*/}
  </>
);

export default WrapWithProviders;
