import React from "react";
import { Link } from "gatsby";
import * as s from "./index.module.scss";

const CompanyLinks = () => {
  return (
    <div>
      <h5 className="bold">Company</h5>
      <ul className={s.list_view_box}>
        <li className="mb-2">
          <Link className="paragraph medium" to="/about-us">
            About us
          </Link>
        </li>
        <li className="mb-2">
          <Link className="paragraph medium" to="/our-work">
            Our work
          </Link>
        </li>
        <li className="mb-2">
          <Link className="paragraph medium" to="/our-work">
            Testimonials
          </Link>
        </li>
        <li className="mb-2">
          <Link className="paragraph medium" to="/blogs">
            Blogs
          </Link>
        </li>
        <li className="mb-2">
          <Link className="paragraph medium" to="/contact-us">
            Contact Us
          </Link>
        </li>
      
      </ul>
    </div>
  );
};

export default CompanyLinks;
