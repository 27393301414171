exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-blogs-blog-blog-template-jsx": () => import("./../../../src/pages/blogs/blog/_blog_template.jsx" /* webpackChunkName: "component---src-pages-blogs-blog-blog-template-jsx" */),
  "component---src-pages-blogs-index-jsx": () => import("./../../../src/pages/blogs/index.jsx" /* webpackChunkName: "component---src-pages-blogs-index-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-work-index-jsx": () => import("./../../../src/pages/our-work/index.jsx" /* webpackChunkName: "component---src-pages-our-work-index-jsx" */),
  "component---src-pages-ppc-index-jsx": () => import("./../../../src/pages/ppc/index.jsx" /* webpackChunkName: "component---src-pages-ppc-index-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/Search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-seo-index-jsx": () => import("./../../../src/pages/seo/index.jsx" /* webpackChunkName: "component---src-pages-seo-index-jsx" */),
  "component---src-pages-services-design-and-development-index-jsx": () => import("./../../../src/pages/services/design-and-development/index.jsx" /* webpackChunkName: "component---src-pages-services-design-and-development-index-jsx" */),
  "component---src-pages-services-mobile-app-development-index-jsx": () => import("./../../../src/pages/services/mobile-app-development/index.jsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-index-jsx" */),
  "component---src-pages-services-performance-marketing-index-jsx": () => import("./../../../src/pages/services/performance-marketing/index.jsx" /* webpackChunkName: "component---src-pages-services-performance-marketing-index-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/_case_study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */)
}

