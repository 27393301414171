import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "gatsby";
import { isDesktop } from "../../../utils";
import HeaderLogo from "./HeaderLogo.svg";
import GrowIconA from "./images/grow_icon.svg";
import GrowIconB from "./images/grow_icon_1.svg";
import ArrowDown from "./images/arrow.svg";
import ArrowUp from "./images/arrow_up.svg";
import * as s from "./index.module.scss";

import "./custom.scss";

const expand = "xl";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => setExpanded(false);
  const [width] = useState(isDesktop ? 176 : 127);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
  });

  const scroll = (e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("href").slice(2);
    const url = window.location.href;
    const url_path = window.location.origin;
    const url_name = window.location.pathname;

    if (url_name !== "/") {
      // window.location.href = `${url_path}/#services`;
    } else {
      const element = document.getElementById(id);
      const navHeight = document
        .querySelector(".my_Nav")
        .getBoundingClientRect().height;
      const linksHeight = document
        .querySelector(".links-nav")
        .getBoundingClientRect().height;
      let position = element.offsetTop - navHeight;
      if (navHeight > 100) {
        position += linksHeight;
      }
      window.scrollTo({
        top: position,
        left: 0,
      });
      setExpanded(false);
    }
  };

  return (
    <Navbar
      expand={expand}
      className={`${s.nav_wrapper}  my_Nav`}
      collapseOnSelect={true}
      expanded={expanded}
    >
      <Container fluid={"xxl"} className={"position-relative header_menu"}>
        <Navbar.Brand as="div">
          <Link to={"/"}>
            <img src={HeaderLogo} alt="Clectiq" className={s.logo} />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={() => setExpanded(true)}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          onHide={closeMenu}
        >
          <Offcanvas.Header closeButton className="mobile_header">
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <img src={HeaderLogo} alt="Clectiq" width={width} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav
              className={`justify-content-end flex-grow-1 pe-3 ${s.main_menu}  links-nav`}
              as={"ul"}
            >
              <Nav.Link as="li">
                <Link
                  to={"/"}
                  onClick={closeMenu}
                  activeClassName={"text-primary"}
                  className="paragraph medium"
                >
                  Home
                </Link>
              </Nav.Link>

              <Nav.Link as="li" className="paragraph medium">
                <NavDropdown
                  className="nav_drop_down"
                  title={
                    <div className="service_view">
                      Services{" "}
                      <img
                        src={ArrowDown}
                        className="arrowDown arrowSize"
                        alt="ArrowDown"
                        loading="lazy"
                      />{" "}
                      <img
                        src={ArrowUp}
                        className="arrowUp arrowSize"
                        alt="ArrowUp"
                        loading="lazy"
                      />
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <div className="drop_down_list_box">
                    <label className={`paragraph regular ${s.title_drop_down}`}>
                      Our expertise
                    </label>
                    <div className="drop_list">
                      <NavDropdown.Item as={"div"} className={s.list_top}>
                        <Link
                          to={"/services/performance-marketing"}
                          className={s.drop_down_link}
                          onClick={closeMenu}
                        >
                          <div className={s.drop_list_content_box}>
                            <img src={GrowIconB} alt={"GrowIconB"} />
                            <div className={s.drop_list_content}>
                              <label className="subTitle font-weight-500">
                                Performance Marketing
                              </label>
                              <label className="caption font-weight-400">
                                Grow your business
                              </label>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={"div"} className={s.list_top}>
                        <Link
                          to="/services/design-and-development/"
                          className={s.drop_down_link}
                          onClick={closeMenu}
                        >
                          <div className={s.drop_list_content_box}>
                            <img src={GrowIconA} alt={"GrowIconA"} />
                            <div className={s.drop_list_content}>
                              <label className="subTitle font-weight-500">
                                Design & Development
                              </label>
                              <label className="caption font-weight-400">
                                Build something amazing
                              </label>
                            </div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                    </div>
                  </div>
                </NavDropdown>
              </Nav.Link>
              <Nav.Link as="li">
                <Link
                  to={"/about-us"}
                  onClick={closeMenu}
                  activeClassName={"text-primary"}
                  className="paragraph medium"
                >
                  Company
                </Link>
              </Nav.Link>
              <Nav.Link as="li">
                <Link
                  to={"/our-work"}
                  onClick={closeMenu}
                  activeClassName={"text-primary"}
                  className="paragraph medium"
                >
                  Portfolio
                </Link>
              </Nav.Link>
              <Nav.Link as="li" className={"pe-0"}>
                <button
                  className={s.buttton}
                  data-tf-popup={"qqaX1lxu"} //E8fzbGlC
                  data-tf-opacity={"100"}
                  data-tf-size={"100"}
                  data-tf-iframe-props={"title=Free Discovery Call"}
                  data-tf-transitive-search-params
                  data-tf-medium={"snippet"}
                  data-tf-hidden={
                    "utm_source=direct&utm_medium=wesbite&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx#default_value=xxxxx"
                  }
                  // data-tf-hidden={
                  //   "utm_source=,utm_medium=,utm_campaign=,utm_term=,utm_content=,hubspot_utk=,hubspot_page_name=,hubspot_page_url="
                  // }
                >
                  Get Your Free Proposal
                </button>
                {/* <button
                  className={s.buttton}
                  onClick={() => {
                    window.location.href = "https://form.typeform.com/to/qqaX1lxu?utm_source=direct&utm_medium=wesbite&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx#default_value=xxxxx";
                  }}
                >
                  Get Your Free Proposal
                </button> */}
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
