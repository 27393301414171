import React from "react";
import { Link } from "gatsby";
import Intro from "./components/Intro";
import ServicesLinks from "./components/ServicesLinks";
import CompanyLinks from "./components/CompanyLinks";
import Subscribe from "./components/Subscribe";
import FollowUs from "./components/FollowUs";
import * as s from "./index.module.scss";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  

  return (
    <footer className={s.footer}>
      <div className="container-xxl">
        <div className="">
          <div className="row">
            <div className="col-lg-5">
              <Intro />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className={`row ${s.center_row}`}>
                <div className="col-sm-6">
                  <ServicesLinks />
                </div>
                <div className={`col-sm-6 ${s.company_left}`}>
                  <CompanyLinks />
                </div>
              </div>
            </div>
          </div>
          <div className={`row ${s.bottom_row}`}>
            <div className={`col-lg-6 ${s.cel_view}`}>
              <Subscribe />
            </div>
            <div className={`col-lg-6 ${s.cel_view}`}>
              <FollowUs />
            </div>
          </div>
          <div className={s.bottom_copy_right}>
            <span className="extra_small regular">
              &copy; {new Date().getFullYear()} Clectiq. All Rights Reserved.
            </span>
            <span className="extra_small regular">
              <Link to="/terms-of-use">Terms of Use</Link> |{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
