// extracted by mini-css-extract-plugin
export var email_icon_box = "index-module--email_icon_box--80cc5";
export var email_input_area = "index-module--email_input_area--ce719";
export var footer_logo = "index-module--footer_logo--e0db3";
export var formControl = "index-module--form-control--b1956";
export var home_icon_box = "index-module--home_icon_box--8c23b";
export var icon = "index-module--icon--afd3d";
export var icon_arrow = "index-module--icon_arrow--9168d";
export var inputGroup = "index-module--input-group--fdb08";
export var list_view_box = "index-module--list_view_box--24e91";
export var list_view_follow = "index-module--list_view_follow--179f7";
export var subscribe_view = "index-module--subscribe_view--18a2a";