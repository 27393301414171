import React from "react";
import * as s from "../index.module.scss";
import GooglePartner from "./images/googlePartner.svg";
import ShopifyPartners from "./images/shopifyPartners.svg";
import TTMarketing from "./images/TTMarketing.svg";
import MetaBusiness from "./images/metaBusiness.svg";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Subscribe = () => {
  return <div className={s.subscribe_view}>
    <img src={GooglePartner} alt="GooglePartner" loading="lazy"/>
    <img src={ShopifyPartners} alt="ShopifyPartners" loading="lazy"/>
    <img src={TTMarketing} alt="TTMarketing" loading="lazy"/>
    <img src={MetaBusiness} alt="MetaBusiness" loading="lazy"/>
  </div>;
};

export default Subscribe;
